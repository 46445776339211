





























































































































































































































































import { Vue, Component } from 'vue-property-decorator';
import { dispatchGetSize } from '@/store/main/actions';
import { appName } from '@/env';
import {
  readDashboardMiniDrawer,
  readDashboardShowDrawer,
  readHasAdminAccess,
  readSize,
  readOtherUsersCodes,
  readUserProfile
} from '@/store/main/getters';
import {
  commitSetDashboardShowDrawer,
  commitSetDashboardMiniDrawer
} from '@/store/main/mutations';
import { dispatchUserLogOut, dispatchChangeUser } from '@/store/main/actions';
import {
  dispatchGetOtherUsersCodes,
  dispatchGetUserProfile,
  dispatchRouteLoggedIn
} from '@/store/main/actions';
import { IUOtherUsersCodes, IUserProfile } from '@/interfaces';
const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/dashboard');
  } else {
    next();
  }
};

@Component
export default class Main extends Vue {
  public loading = false;
  public appName = appName;
  public othercodes: IUOtherUsersCodes = {
    codes_propietario: [{}],
    codes_inquilino: [{}]
  };
  public currentUser = '';

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store)
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store)
    );
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }
  public get size() {
    const isAdmin = readHasAdminAccess(this.$store);
    if (isAdmin) {
      return readSize(this.$store);
    } else {
      return 0;
    }
  }
  public async getSize() {
    this.loading = true;
    await dispatchGetSize(this.$store);
    this.loading = false;
    const isAdmin = readHasAdminAccess(this.$store);
    if (isAdmin) {
      return readSize(this.$store);
    } else {
      return 0;
    }
  }
  public async logout() {
    await dispatchUserLogOut(this.$store);
  }
  get user() {
    const usuario = readUserProfile(this.$store);
    return usuario;
  }
  public async beforeMount() {
    await dispatchGetOtherUsersCodes(this.$store);

    this.othercodes = readOtherUsersCodes(this.$store);

    const usuario = readUserProfile(this.$store);
    if (
      usuario!.codigo_propietario == null ||
      usuario!.codigo_propietario === ''
    ) {
      this.currentUser = usuario!.codigo_inquilino;
    } else {
      this.currentUser = usuario!.codigo_propietario;
    }
  }

  public async changeUser(user) {
    const context = this.$store;
    dispatchChangeUser(this.$store, {
      tipo: user.tipo,
      nCliente: user.code
    });
  }
}
